let mobileSelector = null;
let menuButtonSelector = null;
let menuSelector = null;
let menuButton = null;
let menu = null;
let isActive = false;

if (
  document.getElementById("menu-button") ||
  document.getElementById("menu-button-mobile")
) {
  initLanguageSelectorElements();

  window.addEventListener("resize", () => {
    closeMenu();
    menuButton.removeEventListener("click", watchButton);
    document.removeEventListener("click", watchDocument);
    initLanguageSelectorElements();
  });

  function openMenu() {
    menuButton.setAttribute("data-menu-active", "true");
    menu.classList.add(
      "opacity-100",
      "scale-100",
      "ease-in",
      "duration-75",
      "pointer-events-auto"
    );
    menu.classList.remove(
      "opacity-0",
      "scale-95",
      "ease-out",
      "duration-100",
      "pointer-events-none"
    );
  }

  function closeMenu() {
    menuButton.setAttribute("data-menu-active", "false");
    menu.classList.remove(
      "opacity-100",
      "scale-100",
      "ease-in",
      "duration-75",
      "pointer-events-auto"
    );
    menu.classList.add(
      "opacity-0",
      "scale-95",
      "ease-out",
      "duration-100",
      "pointer-events-none"
    );
  }

  function initLanguageSelectorElements() {
    mobileSelector = window.innerWidth < 640 ? "-mobile" : "";
    menuButtonSelector = "#menu-button" + mobileSelector;
    menuSelector = "#menu" + mobileSelector;
    menuButton = document.querySelector(menuButtonSelector);
    menu = document.querySelector(menuSelector);

    menuButton.addEventListener("click", watchButton);
    document.addEventListener("click", watchDocument);
  }

  function watchButton() {
    isActive = menuButton.dataset.menuActive === "true";
    if (isActive) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  function watchDocument(event) {
    if (!event.target.matches(menuButtonSelector)) {
      closeMenu();
    }
  }

  const hamburgerButton = document.getElementById("hamburger-menu-button");
  const slideOverMenu = document.getElementById("slide-over-menu");
  const slideOverOverlay = document.getElementById("slide-over-overlay");
  const slideOverContainer = document.getElementById("slide-over-container");
  const slideOverButton = document.getElementById("slide-over-button");

  hamburgerButton.addEventListener("click", (event) => {
    openSlideOverMenu();
  });

  slideOverButton.addEventListener("click", (event) => {
    closeSlideOverMenu();
  });

  slideOverOverlay.addEventListener("click", (event) => {
    closeSlideOverMenu();
  });

  function openSlideOverMenu() {
    slideOverContainer.classList.remove("pointer-events-none");
    slideOverMenu.classList.add("translate-x-0");
    slideOverOverlay.classList.add("opacity-100");
  }

  function closeSlideOverMenu() {
    slideOverContainer.classList.add("pointer-events-none");
    slideOverMenu.classList.remove("translate-x-0");
    slideOverOverlay.classList.remove("opacity-100");
  }

  // Loan amount slider and input
  const loanAmountSlider = document.querySelector('#js-amount-range');
  const loanAmountInput = document.querySelector('#js-amount-input');

  if (loanAmountSlider && loanAmountInput) {
    const minValue = loanAmountSlider.getAttribute('min');
    const maxValue = loanAmountSlider.getAttribute('max');
    const defaultValue = loanAmountSlider.dataset.default;
    const numberFormatter = Intl.NumberFormat("nl-NL");

    const getNumericInputValue = function (event) {
      return +(event.target.value?.replaceAll(",", "").replaceAll(".", ""));
    }

    const updateLoadSlider = function (value) {
      loanAmountSlider.style.backgroundSize = (value - minValue) * 100 / (maxValue - minValue) + '% 100%'
    }

    const updateLoanAmount = function (event) {
      let value = getNumericInputValue(event);

      if (isNaN(value)) {
        value = defaultValue;
      } else if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }

      loanAmountInput.value = numberFormatter.format(value);
      loanAmountSlider.value = value;

      updateLoadSlider(value);
    };

    loanAmountSlider.addEventListener('input', event => updateLoanAmount(event));
    loanAmountInput.addEventListener('blur', event => updateLoanAmount(event));

    updateLoadSlider(defaultValue);
  }
}
